import { IS_PRODUCTION_LIKE, Environments as env, ENV, IS_BACKEND_PICASSO } from "../config/environments";

const PRODUCTION = {
  productionLike: true,

  // Visualizer and metrics
  exportToXls: true,
  hasBlankFilter: true,
  timezoneShifting: true,
  sortConfigPanel: true,
  recordProcessPanelInViz: true,
  combinationChart: true,
  mapChart: true,
  australiaMapChart: false,
  urlFormatOptions: true,
  saveVizModalRevamp: true,
  optimizeAllDataPoint: false,
  configureTableViewRevamp: true,
  carryStoryboardFilters: true,
  bubbleScaling: true,
  logicalFieldsInCharts: true,
  sortingForLineCharts: false,
  copyInterpretation: true,
  renameInterpretation: true,
  hideMeteData: true,

  // Storyboards
  globalFiltersMultiselect: true,
  globalFiltersConditional: true,
  valueLazyLoading: true,
  showLoadingSpinner: true,
  lazyLoadingBoards: true,
  relativeDateFilters: true,
  chartFormatOptions: true,
  duplicateStoryboards: true,
  graphqlFlipper: false,
  exportStoryboard: true,
  hideSummaryTotals: false,
  displaySummaryMenu: true,
  pdfPasswordOptions: true,

  // storyboard sharing with user groups
  userGroupsSharePanel: true,

  // Permanent flippers because of AX/ANR
  exportToCsv: true,
  remediationColumns: true,
  remediationToggles: true,
  saveViz: {
    linkMostRecentTable: false,
    shareToGRC: false,
  },
  table: {
    showFieldName: true,
  },
  processExceptionsPanel: true,
  showOnPremiseVizNavBar: false,
  airbrake: [env.STAGING, env.PRODUCTION].includes(ENV),

  saveDefault: true,
  boardSelectorSelectedCheckmark: true,

  // debugging flippers
  debugLogger: false,
  detectScrollToBottomByHeight: false,

  // Freezing checkbox column
  fixedColumnLeftInViz: true,

  //Flippers moved from backend while removing global variables, keeping it here for consistency
  exportTableDataToCsv: true,
  processRecords: true,
  statusQuestionnaires: true,
  highbondNavigationInVisualizer: true,
  featureVizBreadcrumb: true,
  storyboardHomePage: true,
  unformattedValues: false,
  autoApply: false,
  galvanizeHighbond: true,

  //Picasso
  picassoBackend: IS_BACKEND_PICASSO,
  notRestoreJQueryLegacyHtmlPrefilter: true,
  applyFilterInQuickMenu: true,
  //angular to react migration
  migrateChartPickerModal: true,
  migrateLineChart: true,
  migrateBarChart: true,
  migratePiechart: true,
  migrateMapchart: true,
  migrateStackedAreaChart: true,
  migrateBubbleChart: true,
  migrateChartBaseController: true,
  migrateHeatMapChart: true,
  migrateTreemapChart: true,
  migrateStatisticsChart: true,
  migrateSummaryTableChart: true,
  enableRecordCount: true,
  migrateVizMultiSelectFilter: false,

  // results impact report toggles
  showReportDownload: true,
  enableSelectAllForMultiSelect: true,
  migrateVizQuickFilter: true,
};

const E2E_OVERRIDES = {
  copyingTriggers: true,
  exportTableDataToCsv: true,
  saveViz: { linkMostRecentTable: false, shareToGRC: true },
  exportToCsv: true,
  exportToXls: true,
  metricApp: true,
  hasBlankFilter: true,
  metricTriggers: true,
  processRecords: true,
  processExceptionsPanel: true,
  unformattedValues: true,
  queryService: false,
  logan: false,
  galvanizeHighbond: true,
  highbondNavigationInVisualizer: true,

  // turn on fixed column in e2e until grcprd-3466 fixed
  fixedColumnLeftInViz: false,
};

const PRODUCTION_STORYBOARD = {
  ...PRODUCTION,
  queryService: true,
  drillDownButton: true,

  //grcprd-3747-deleting global filter from storyboard when the filters become orphan
  deleteInvalidStoryboardGlobalFilters: true,

  //to enable pagination on storyboard share panel
  enablePaginationOnManageUsers: true,
  //enable Pagination on current users for production
  enablePaginationOnCurrentUsers: true,
  enableStranglerApi: false,
};

const CY_OVERRIDES = {
  queryService: true,

  // turn on fixed column in e2e until grcprd-3466 fixed
  fixedColumnLeftInViz: false,
};

const VISUALIZER_AND_METRICS_STAGING = {
  unformattedValues: true,
  stagingLike: true,
  exportToXls: true,
  timezoneShifting: true,
  logan: false,
  combinationChart: true,
  mapChart: true,
  australiaMapChart: true,
  chartFormatOptions: true,
  urlFormatOptions: true,
  summaryTableFreezeRowHeaders: false,
  saveVizModalRevamp: true,
  optimizeAllDataPoint: true,
  carryStoryboardFilters: true,
  configureTableViewRevamp: true,
  bubbleScaling: true,
  logicalFieldsInCharts: true,
  sortingForLineCharts: false,
  hideSummaryTotals: false,
  displaySummaryMenu: true,
  featureVizBreadcrumb: false,
  copyInterpretation: true,
  notRestoreJQueryLegacyHtmlPrefilter: true,
  applyFilterInQuickMenu: true,
  renameInterpretation: true,
  //angular to react migration
  migrateChartPickerModal: true,
  migrateLineChart: true,
  migrateBarChart: true,
  migratePieChart: true,
  migrateMapchart: true,
  migrateBubbleChart: true,
  migrateChartBaseController: true,
  migrateStackedAreaChart: true,
  migrateCombinationChart: true,
  migrateTreemapChart: true,
  migrateHeatMapChart: true,
  migrateStatisticsChart: true,
  migrateSummaryTableChart: true,
  enableRecordCount: true,

  // results impact report toggles
  showReportDownload: true,

  // Enabling freezing checkbox column in stage
  fixedColumnLeftInViz: true,

  //Select unselect filter
  enableSelectAllForMultiSelect: true,

  hideMeteData: true,
  migrateVizQuickFilter: true,

  // Migrate services
  migrateEventService: true,
  migrateDataModelService: false,
  migrateVizServices: false,
  highbondNavigationInVisualizer: true,
};

const STORYBOARD_STAGING = {
  valueLazyLoading: false,
  logan: false,
  timezoneShifting: true,
  highbondNavigationInVisualizer: true,
  boardSelectorSelectedCheckmark: true,
  relativeDateFilters: true,
  drillDownButton: true,
  duplicateStoryboards: true,
  graphqlFlipper: true,
  deleteInvalidStoryboardGlobalFilters: true,
  carryStoryboardFilters: true,
  exportStoryboard: true,
  hideSummaryTotals: false,
  queryService: true,
  notRestoreJQueryLegacyHtmlPrefilter: true,
  pdfPasswordOptions: true,
  // storyboard sharing with user groups
  userGroupsSharePanel: true,

  // Migrate visualization board component
  MigrateVisualizationBoard: true,

  //Select unselect filter
  enableSelectAllForMultiSelect: true,
  //enabling pagination and count
  enablePaginationOnManageUsers: true,

  //enable Pagination on current users
  enablePaginationOnCurrentUsers: true,
  enableStranglerApi: false,
};

export function e2eFlippers() {
  return { ...PRODUCTION, ...VISUALIZER_AND_METRICS_STAGING, ...STORYBOARD_STAGING, ...E2E_OVERRIDES };
}

export function cypressE2eFlippers() {
  return { ...PRODUCTION, ...VISUALIZER_AND_METRICS_STAGING, ...E2E_OVERRIDES };
}

export function visualizerAndMetricsFlippers() {
  if (IS_PRODUCTION_LIKE) {
    return { ...PRODUCTION };
  }
  if (window.Cypress) {
    return cypressE2eFlippers();
  }
  return { ...PRODUCTION, ...VISUALIZER_AND_METRICS_STAGING };
}

export function storyboardFlippers() {
  if (IS_PRODUCTION_LIKE) {
    return { ...PRODUCTION_STORYBOARD };
  }
  if (window.Cypress) {
    return { ...e2eFlippers(), ...CY_OVERRIDES };
  }
  return { ...PRODUCTION_STORYBOARD, ...STORYBOARD_STAGING };
}
